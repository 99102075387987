import React from "react"
import Layout from "../components/layout"

const Success = () => {
  return (
    <Layout>
      <div className="h-screen gradient py-24">
        <div className="mx-auto shadow-custom p-8 rounded bg-white w-4/5 mx-auto">
          <h2 className="text-center">Success!</h2>
          <p className="w-4/5">
            Thank you for purchase! <br /> We will be in contact with futher
            details about your purchase soon.
          </p>
          <p>
            Please note that it can take up to <code> 24 Hours </code> for an
            email to be sent. But if you still haven't recieved it within a day
            please contact us at hey@influencerlist.com.
          </p>
          <p>
            <i>
              Make sure to double check your spam or trash inbox for our e-mail
              so it doesn't get lost. It will always come from the
              hey@theinfluencerlist.co (or @stripe) domain.
            </i>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Success
